import { authAxios } from '@/api';
import { API_VER, ROOT_URL } from '@/app/constants';

const API_CONTROLLER = 'events';

export class eventApi {
  static getEvents() {
    const url = `${ROOT_URL}/${API_VER}/${API_CONTROLLER}/list-events`;
    return authAxios.get(url);
  }
}
