<template>
  <div>
    <div class="header">
      {{ event.eventName }}
    </div>
    <div>
      {{ event.presentedBy }}
    </div>
    <div class="time">
      {{ event.eventTime }}
    </div>
    <p>
      {{ event.description }}
    </p>
    <v-btn color="primary"
           :href="event.link"
           target="_blank">
      Register
    </v-btn>
  </div>
</template>

<script>
import { eventApi } from '@/api/eventApi';

export default {
  name: 'EventListItem',
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      eventList: [],
    };
  },

};
</script>

<style scoped
       lang="scss">
.event-block {
  margin-top: 30px;

  * {
    margin-bottom: 10px;
  }

  .header {
    font-weight: bold;
    font-size: 1.2rem;
  }

  .time {
    font-style: italic;
  }
}
</style>
