
import { authAxios } from '@/api';
import { API_VER, ROOT_URL } from '@/app/constants';

const API_CONTROLLER = 'admin/events';


export class adminEventApi {
  static searchEvents(searchRequest) {
    const url = `${ROOT_URL}/${API_VER}/${API_CONTROLLER}/search-events`;
    return authAxios.post(url, searchRequest);
  }
}
