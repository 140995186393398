<template>
  <div class="container">
    <h1>Events List</h1>

    <div v-for="event in eventList">
      <div>
        <event-list-item :event="event"></event-list-item>

        <v-btn color="primary">Edit</v-btn>
        <v-btn color="red">Delete</v-btn>
      </div>

    </div>


  </div>
</template>

<script>
import EventListItem from '@/components/EventListItem';
import Component from 'vue-class-component';
import Vue from 'vue';
import { adminEventApi } from '@/api/adminEventApi.ts';

@Component({
  components: { EventListItem },
})
export default class AdminEventList extends Vue {

  eventList = [];
  eventListHeaders = [];

  async mounted() {
    await this.loadEventList();
  }

  async loadEventList() {
    const eventSearchRequest = {
      searchPage: {
        pageIndex: 0,
        currentPage: 1,
        pageSize: 100,
      },
    };
    const { data } = await adminEventApi.searchEvents(eventSearchRequest);
    this.eventList = data;
  }
}

/*
export default {
  name: 'EventsList',
  components: { EventListItem },
  data() {
    return {
      eventList: [],

      eventListHeaders: [],
    };
  },
  ,
  methods: {
    ,
  },
};
*/

</script>

<style scoped
       lang="scss">

</style>
